<template>
    <div class="index w">
        <div class="btn-group">
            <div class="w flex-b">
                <div>
                    <el-button type="primary" @click="addFile"
                        >添加单选题</el-button
                    >
                    <el-button type="primary" @click="addSimple"
                        >添加简答题</el-button
                    >
                    <el-button type="success" @click="toSubmitWork"
                        >发布作业</el-button
                    >
                    <el-button type="link" @click="showPic = !showPic"
                        >{{ !showPic ? "显示" : "隐藏" }}上传图片功能</el-button
                    >
                    <el-button type="link" @click="editAll" >整体编辑</el-button>
                </div>
                <p class="flex-e">
                    <img src="../assets/logo.png" class="logo" />
                    <span>好芳法课堂</span>
                </p>
            </div>
        </div>
        <div class="question-list">
            <div v-for="(item, index) in list" :key="index">
                <div class="question-item" v-if="item.type == 'radio'">
                    <p class="flex-e" style="padding: 10px 0; margin: 0">
                        <i
                            class="tip"
                            v-text="
                                '第' +
                                (index + 1) +
                                '题 / 共' +
                                list.length +
                                '题'
                            "
                        ></i>
                        <img
                            src="../assets/edit-btn.png"
                            class="edit-btn"
                            v-if="!item.isEdit"
                            @click="toggleEdit(item, index)"
                        />
                        <img
                            src="../assets/edit-btn.png"
                            class="edit-btn"
                            v-else
                            @click="item.isEdit = false"
                        />
                        <img
                            src="../assets/del-btn.png"
                            @click="removeitem(index)"
                            class="del-btn"
                        />
                    </p>
                    <p class="title" v-text="item.name" v-if="!item.isEdit"></p>
                    <el-input
                        v-model="item.name"
                        placeholder="请输入题目"
                        class="title-input"
                        v-else
                    ></el-input>
                    <p class="file-box">
                        <img v-if="item.fileUrl" :src="item.fileUrl" />
                        <i
                            v-if="item.fileUrl && item.isEdit"
                            class="el-icon-error sm-del-btn"
                            @click="delImg(item, index)"
                        ></i>
                        <img
                            v-else-if="!item.fileUrl && item.isEdit && showPic"
                            :src="'https://wangfang.oss-cn-qingdao.aliyuncs.com/adInfo/add-pic.png'"
                        />
                        <input
                            v-if="item.isEdit"
                            @change="getFile($event, index)"
                            type="file"
                            accept="imgage/*"
                            class="input-uploader"
                        />
                    </p>
                    <div class="option-list">
                        <p
                            class="option-item flex-s"
                            v-for="(child, c) in item.option"
                            :key="'c' + c"
                            style="width: 100%; text-align: left"
                        >
                            <el-radio
                                v-model="item.answer"
                                :disabled="!item.isEdit"
                                :label="child"
                                class="f1"
                                style="width: calc(100% - 100px)"
                            >
                                <el-input
                                    v-if="item.isEdit"
                                    v-model="item.option[c]"
                                    class="f1"
                                ></el-input>
                                <span v-text="child" v-else></span>
                            </el-radio>
                            <el-button
                                type="danger"
                                size="mini"
                                v-if="item.isEdit"
                                @click.native="removeOption(index, c)"
                                >删除</el-button
                            >
                        </p>
                        <p class="flex-s" v-if="item.isEdit">
                            <el-input
                                v-model="item.newOption"
                                placeholder="请输入选项，选中的为正确答案"
                                class="user-input"
                               @keydown.native="e=>onKeydown(e,item, index)"
                            ></el-input>
                            <el-button @click="addOptions(item, index)"
                                >添加选项</el-button
                            >
                        </p>
                    </div>
                </div>
                <div
                    class="short-answer-item question-item"
                    v-else-if="item.type == 'shortAnswer'"
                >
                    <p class="flex-e">
                        <el-checkbox
                            :disabled="!item.isEdit"
                            v-model="item.rewrite"
                            >允许重做</el-checkbox
                        >
                        <el-checkbox
                            style="margin-left: 10px; margin-right: 15px"
                            :disabled="!item.isEdit"
                            v-model="item.isAudit"
                            >是否审批</el-checkbox
                        >
                        <i
                            class="tip"
                            v-text="
                                '第' +
                                (index + 1) +
                                '题 / 共' +
                                list.length +
                                '题'
                            "
                        ></i>
                        <img
                            src="../assets/edit-btn.png"
                            class="edit-btn"
                            v-if="!item.isEdit"
                            @click="toggleEdit(item, index)"
                        />
                        <img
                            src="../assets/edit-btn.png"
                            class="edit-btn"
                            v-else
                            @click="item.isEdit = false"
                        />
                        <img
                            src="../assets/del-btn.png"
                            @click="removeitem(index)"
                            class="del-btn"
                        />
                    </p>
                    <p class="title" v-text="item.name" v-if="!item.isEdit"></p>
                    <el-input
                        v-model="item.name"
                        type="textarea"
                        rows="3"
                        placeholder="请输入题目"
                        v-else
                    ></el-input>
                    <p class="file-box">
                        <img v-if="item.fileUrl" :src="item.fileUrl" />
                        <i
                            v-if="item.fileUrl && item.isEdit"
                            class="el-icon-error sm-del-btn"
                            @click="delImg(item, index)"
                        ></i>
                        <img
                            v-else-if="!item.fileUrl && item.isEdit"
                            :src="'https://wangfang.oss-cn-qingdao.aliyuncs.com/adInfo/add-pic.png'"
                        />
                        <input
                            v-if="item.isEdit"
                            @change="getFile($event, index)"
                            type="file"
                            accept="imgage/*"
                            class="input-uploader"
                        />
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import OSS from "ali-oss";
import { Message } from "element-ui";
export default {
    data() {
        return {
            now: -1,
            list: [],
            id: "",
            url: null,
            radio: 3,
            type: 0,
            showPic: false,
        };
    },
    watch: {
        list: {
            handler(val) {
                let fakeList = [];
                let flag = true;
                val.forEach((item, index) => {
                    let answer = item.answer,
                        name = item.name,
                        option = item.option;
                    if (item.type == "radio") {
                        if (
                            !answer ||
                            answer.replace(/\s/g, "") == "" ||
                            !name ||
                            name.replace(/\s/g, "") == "" ||
                            !option ||
                            option.length == 0
                        ) {
                            fakeList.push(index);
                        }
                    } else if (item.type == "shortAnswer") {
                        if (!name || name.replace(/\s/g, "") == "") {
                            fakeList.push(index);
                        }
                    }
                });
                if (fakeList.length > 1) {
                    this.list.splice(fakeList[0], 1);
                }
                console.log(this.list);
            },
            deep: true,
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        editAll() {
            this.list.forEach((item) => {
                item.isEdit = true;
            });
        },
        toSubmitWork() {
            let shortAnswer = this.list.filter(
                (item) => item.type == "shortAnswer"
            );
            let radio = this.list.filter((item) => item.type == "radio");
            if (shortAnswer.length > 0 && radio.length == 0) {
                this.type = 2;
                // console.log("简答题");
                this.submitWork();
            } else if (radio.length > 0 && shortAnswer.length == 0) {
                this.type = 1;
                // console.log("单选题");
                this.submitWork();
            } else {
                this.$confirm("暂不支持混合题型", "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    showClose: true,
                    distinguishCancelAndClose: true,
                });
            }
        },
        addSimple() {
            let list = this.list.concat([]);
            this.type = 2;
            list.push({
                type: "shortAnswer",
                name: "",
                id: guid(),
                isEdit: true,
                fileUrl: null,
                value: "",
                answer: "",
                option: [],
                rewrite: false,
                isAudit: true,
                newOption: "",
            });
            this.list = list;
        },
        toggleEdit(item, index) {
            this.list[index].isEdit = !this.list[index].isEdit;
        },
        getFile(e, i) {
            let file = e.target.files[0];
            let fr = new FileReader();
            fr.readAsDataURL(file);
            let _this = this;
            fr.onload = function () {
                let oriBase64 = this.result;
                const base64 = oriBase64.split(",").pop(); //出去头部image/data
                const fileType = oriBase64.split(";").shift().split(":").pop();
                const blob = _this.toBlob(base64, fileType);
                const client = new OSS({
                    region: "oss-cn-qingdao",
                    accessKeyId: "LTAI4GHHTBQDuNVoWNyP2Uax",
                    accessKeySecret: "boqwd4HjS2Zge0r5VAfcxK2Cwc86UM",
                    bucket: "wangfang",
                });
                _this.putBlob(client, blob).then((res) => {
                    _this.url = res.url;
                    _this.list[i].fileUrl = res.url;
                });
            };
        },
        delImg(item, index) {
            this.list[index].fileUrl = null;
        },
        putBlob(client, blob) {
            return new Promise((resolve, reject) => {
                try {
                    let timestamp = Date.parse(new Date());
                    let fileName = `homework/homework-${this.id}-${timestamp}.png`;
                    client.put(fileName, blob).then((res) => {
                        resolve(res);
                    });
                } catch (e) {
                    reject(e);
                }
            });
        },
        toBlob(urlData, fileType) {
            let bytes = window.atob(urlData);
            let n = bytes.length;
            let u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bytes.charCodeAt(n);
            }
            return new Blob([u8arr], { type: fileType });
        },
        over(index) {
            setTimeout(() => {
                this.now = index;
            }, 100);
        },
        init() {
            // let id = 219;
            let id = GetRequest();
            this.id = id;
            axios({
                method: "POST",
                url: "/course/work/previewForm/" + this.id,
            }).then((res) => {
                if (res.data.data) {
                    let data = JSON.parse(res.data.data);
                    data.forEach((item) => {
                        item.isEdit = false;
                    });
                    this.list = data;
                }
            });
        },
        removeitem(index) {
            this.list.splice(index, 1);
        },
        addFile() {
            this.url = null;
            this.type = 1;
            this.list.push({
                type: "radio",
                name: "",
                id: guid(),
                isEdit: true,
                fileUrl: null,
                value: "",
                answer: "",
                option: [],
                rewrite: false,
                isAudit: true,
            });
            // 最后一个添加焦点
            this.$nextTick(() => {
                const list = document.querySelectorAll(".question-item");
                if (list && list.length) {
                    const last = list[this.list.length - 1];
                    if (last) {
                        const lastInputWrapper =
                            last.querySelector(".title-input");
                        if (lastInputWrapper) {
                            const lastInput =
                                lastInputWrapper.querySelector("input");
                            if (lastInput) {
                                // console.log("lastInput", lastInput);
                                lastInput.focus();
                            }
                        }
                    }
                }
            });
        },
        addOptions(item, index) {
          console.log(item.newOption)
            if (item.newOption != "") {
                this.list[index].option.push(item.newOption);
                item.newOption = "";
            }
            // 最后一个添加焦点
            this.$nextTick(() => {
                const list = document.querySelectorAll(".question-item");
                if (list && list.length) {
                    const last = list[index];
                    if (last) {
                        const lastInputWrapper =
                            last.querySelector(".user-input");
                        if (lastInputWrapper) {
                            const lastInput =
                                lastInputWrapper.querySelector("input");
                            if (lastInput) {
                                // console.log("lastInput", lastInput);
                                lastInput.focus();
                            }
                        }
                    }
                }
            });

        },
        onKeydown(e,item,index){
            if(e.keyCode==13){
                this.addOptions(item,index)
            }
        },
        removeOption(index, i) {
            this.list[index].option.splice(i, 1);
        },
        submitWork() {
            let list = this.list;
            if (list.length > 0) {
                let flag = true;
                let fakeList = [];
                this.list.forEach((item, index) => {
                    let answer = item.answer,
                        name = item.name,
                        option = item.option;
                    if (item.type == "radio") {
                        if (!answer || answer.replace(/\s/g, "") == "") {
                            const message_conf = {
                                showClose: true,
                                message: `第${index + 1}题未勾选正确答案`,
                                type: "success",
                                duration: 3000,
                            };
                            Message(message_conf);
                            fakeList.push(index);
                            flag = false;
                        } else if (!name || name.replace(/\s/g, "") == "") {
                            const message_conf = {
                                showClose: true,
                                message: `第${index + 1}题未填写标题`,
                                type: "success",
                                duration: 3000,
                            };
                            Message(message_conf);
                            fakeList.push(index);
                            flag = false;
                        } else if (!option || option.length == 0) {
                            const message_conf = {
                                showClose: true,
                                message: `第${index + 1}题未设置选项`,
                                type: "success",
                                duration: 3000,
                            };
                            Message(message_conf);
                            fakeList.push(index);
                            flag = false;
                        }
                    } else if (item.type == "shortAnswer") {
                        if (!name || name.replace(/\s/g, "") == "") {
                            const message_conf = {
                                showClose: true,
                                message: `第${index + 1}题未填写标题`,
                                type: "success",
                                duration: 3000,
                            };
                            Message(message_conf);
                            fakeList.push(index);
                            flag = false;
                        }
                    }
                });
                if (flag) {
                    axios({
                        method: "POST",
                        url: "/course/work/updateForm",
                        data: {
                            id: this.id,
                            data: JSON.stringify(this.list),
                            type: this.type,
                        },
                    }).then((res) => {
                        if (res.data.status == 200) {
                            const message_conf = {
                                showClose: true,
                                message: "提交作业成功^_^",
                                type: "success",
                                duration: 3000,
                            };
                            Message(message_conf);
                            this.init();
                        } else {
                            const message_conf = {
                                showClose: true,
                                message: "提交作业失败>_<",
                                type: "error",
                                duration: 3000,
                            };
                            Message(message_conf);
                        }
                    });
                }
                //   else {
                //     let delList = fakeList.reverse();
                //     delList.forEach(index => {
                //       this.removeitem(index);
                //     });
                //     this.submitWork();
                //   }
            }
        },
    },
};
function guid() {
    return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(32);
    });
}
function GetRequest() {
    var id = location.href.split("id=")[1]; //获取url中"?"符后的字串
    if (id) {
        return id;
    } else {
        return null;
    }
}
</script>
<style lang="less" scoped>
//  background: url("https://wangfang.oss-cn-qingdao.aliyuncs.com/adInfo/homework-iphonex.png")
.w {
    width: 800px;
    margin: 0 auto;
}

.edit-btn {
    width: 30px;
}
.del-btn {
    width: 24px;
}
.index {
    position: relative;
    box-sizing: border-box;
    padding-top: 100px;
    .question-item {
        box-sizing: border-box;
        // padding: 10px 30px;
        padding: 0px 20px 10px;
        border: 1px dashed #ccc;
        border-radius: 4px;
        margin-bottom: 10px;
        &:hover {
            border-color: #409eff;
        }
        .title {
            font-weight: bold;
            font-size: 18px;
            margin: 0;
            margin-bottom: 10px;
            text-align: left;
        }
        .file-box {
            width: 100px;
            text-align: center;
            max-height: 200px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .input-uploader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;
        }
    }

    .logo {
        height: 25px;
        margin-right: 5px;
    }
    .flex-b {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .flex-e {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .flex-s {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .btn-group {
        position: fixed;
        top: 0;
        z-index: 1;
        left: 0;
        width: 100%;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        padding: 15px 30px;
        box-shadow: 0 2px 8px #eee;
        background-color: #fff;
    }
    .text-r {
        text-align: right;
    }
    .del-btn {
        color: #f56c6c;
        font-size: 24px;
        cursor: pointer;
    }
    .sm-del-btn {
        color: #f56c6c;
        font-size: 18px;
        position: absolute;
        right: -9px;
        top: -9px;
        background-color: #fff;
        border-radius: 50%;

        cursor: pointer;
    }
    .edit-btn {
        font-weight: bold;
        font-size: 24px;
        color: #409eff;
        margin-right: 20px;
        cursor: pointer;
    }
    .success-btn {
        font-weight: bold;
        font-size: 24px;
        color: #67c23a;
        margin-right: 20px;
        cursor: pointer;
    }
    .tip {
        margin-right: 10px;
        color: #999;
        font-size: 16px;
    }
    .del-text {
        margin-left: 20px;
        cursor: pointer;
    }
    p {
        margin: 10px 0;
    }
}
</style>
